import React, { Component } from 'react'
import Helmet from 'react-helmet'
import smoothscroll from 'smoothscroll-polyfill';

import Header from '../components/header'
import Footer from '../components/footer'
import Transition from '../components/transition'

import '/node_modules/plyr/dist/plyr.css'
import '../assets/scss/main.scss'
import '../assets/scss/fonts.css'


import favicon_16 from '../assets/favicons/favicon-16x16.png';
import favicon_32 from '../assets/favicons/favicon-32x32.png';
import apple_touch from '../assets/favicons/apple-touch-icon.png';
import mask_icon from '../assets/favicons/safari-pinned-tab.svg';
import og_image from '../assets/images/round3_poster.jpg'

// kick off the polyfill!
typeof window !== 'undefined' && smoothscroll.polyfill();
require(`../../node_modules/font-awesome/css/font-awesome.css`)

class TemplateWrapper extends Component {
  state = {
    title: '',
  }

  componentDidMount() {
    this._findTitle();
  }

  componentWillReceiveProps() {
    this._findTitle();
  }

  _findTitle() {
    let title = window.location.pathname;
    title.indexOf(1);
    title.toLowerCase();
    title = title.split("/")[1].replace('-', ' ');
    this.setState({ title });
  }

  render() {
    return (
      <div>
        <Helmet defaultTitle={`Round3`} titleTemplate={`%s | Round3`}>

          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Round3" />
          <meta property="og:url" content={`https://round3.com.au${this.props.location.pathname}`} />
          <meta property="og:image" content={`https://round3.com.au${og_image}`} />

          <meta name="viewport" content="width=device-width, initial-scale=1.0" />

          <link rel="apple-touch-icon" sizes="180x180" href={apple_touch} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon_32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon_16} />
          <link rel="mask-icon" href={mask_icon} color="#25dbc9" />
          <meta name="msapplication-TileColor" content="#25dbc9" />
          <meta name="theme-color" content="#25dbc9" />

          <link
            rel="canonical"
            href={`https://round3.com.au${this.props.location.pathname}`}
          />
          <html lang="en" />
        </Helmet>
        <Header title={this.props.title || this.state.title} />
        <main>
          <Transition>
            { this.props.children }
          </Transition>
        </main>
        <Footer />
      </div>
    );
  }
}

export default TemplateWrapper
