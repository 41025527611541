import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Reveal from 'react-reveal/Reveal'
import Link from 'gatsby-link'

class Button extends Component {
  state = {
    top: 0,
    left: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this._onScroll())
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onScroll())
  }

  _onScroll = () => (event) => {
    if (!this.refs.btn) return false
    let { height, y, width, x } = this.refs.btn.getBoundingClientRect()
    let top = ((window.outerHeight / 2.5) - ( y + (height / 2) )) * -0.1
    let left = top
    this.setState({ top, left })
  }

  render() {
    let { top, left } = this.state
    return (
      <div ref='btn'>
        <Link className='btn btn--white btn--scroll' to={this.props.to}>
          { this.props.children }
          <div className='btn__shadow' style={{top, left}} />
        </Link>
      </div>
    )
  }
}

Button.propTypes = {
  to: PropTypes.string,
}
 
export default Button
