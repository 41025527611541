import React, { Component } from 'react'
import Reveal from 'react-reveal/Reveal'
import Link from 'gatsby-link'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import Button from './button'

const url = 'https://round3.us13.list-manage.com/subscribe/post?u=211f099736c9591ba75175654&id=8d33de0d7f'


class CustomForm extends Component {
  render() {
    let { status, message, onValidated, close } = this.props

    let email, name;

    const submit = (event) => {
      event.preventDefault()
      if (email && name && email.value.indexOf('@') > -1) {
        onValidated({
          EMAIL: email.value,
          NAME: name.value
        })
      }
    }

    return (
      <div>
        <div className='form__overlay'></div>
        <form className='form form--subscribe' onSubmit={submit}>
          <div className='form__close' onClick={close}>
            <div className='lines'></div>
          </div>
          {status === 'sending' && <p>Sending...</p>}
          {status === 'error' && (
            <p
              style={{ color: '#ff4f50' }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === 'success' && (
            <p
              style={{ color: '#25DBC9' }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          <input
            ref={node => (name = node)}
            type='text'
            placeholder='Your name'
          />
          <br />
          <input
            ref={node => (email = node)}
            type='email'
            placeholder='Your email'
          />
          <br />
          <button className='btn' type='submit'>
            Submit
          </button>
        </form>
      </div>
    );
  }
}

class Footer extends Component {
  state = {
    subscribe: false
  }
  // <p>{'Made with <3 in Melbourne, Australia'}</p>

  _subscribe = (state) => (event) => {
    if (event) event.preventDefault()
    if (typeof state === 'boolean')
      this.setState({ subscribe: state })
    else
      this.setState({ subscribe: !this.state.subscribe })
  }

  render() {
    return (
      <div>
        <div className='footer__contact'>
          <Reveal effect='fadeInUp'>
            <h2>Create with us?</h2>
            <p>We’ve got you covered anywhere in the globe. Just ask.</p>
            <Link className='btn btn--white' to='/contact'>Get in touch →</Link>
          </Reveal>
        </div>
        <div className='footer__acknowledgement'>
          <p>Round 3 acknowledges the Wurundjeri Woi Wurrung people, the traditional owners and sovereign custodians of the land where we collaborate and work from. We pay our respect to all First Nation peoples, their Ancestors  and Elders past, present, and emerging.</p>
        </div>
        <footer>
          <div className='footer__email'>
            <a target='_blank' href='mailto:info@round3.com.au'>info@round3.com.au</a>

          </div>
          <div className='footer__social'>
            <ul>
              <li>
                  <a href='https://www.facebook.com/round3films/' target='_blank'>
                    <i className='fa fa-facebook'></i>
                  </a>
              </li>
              <li>
                  <a href='https://www.instagram.com/round3films/' target='_blank'>
                    <i className='fa fa-instagram'></i>
                  </a>
              </li>
              <li>
                  <a href='https://vimeo.com/round3' target='_blank'>
                    <i className='fa fa-vimeo'></i>
                  </a>
              </li>
              <li><a href='#' onClick={this._subscribe()}><p>Subscribe</p></a></li>
            </ul>
          </div>
        </footer>
        { this.state.subscribe &&
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => {
              if (status === 'success') {
                setTimeout(this._subscribe(false), 1000)
              }
              return (
                <CustomForm
                  status={status}
                  message={message}
                  close={this._subscribe(false)}
                  onValidated={formData => subscribe(formData)}
                />
              )
            }}
          />
        }
      </div>
    )
  }
}

export default Footer
