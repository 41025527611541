import React, { Component } from 'react'
import Link from 'gatsby-link'

import brand from '../assets/images/brand-logo.svg'

class Header extends Component {

  state = {
    active: false,
    menuActive: false
  };

  constructor(props) {
    super(props);

    this._hideOffCanvas = this._hideOffCanvas.bind(this);
    this._showOffCanvas = this._showOffCanvas.bind(this);
  }

  _showOffCanvas() {
    this.setState({menuActive: true});
    document.querySelector('body').classList.add('menu-open');
  }

  _hideOffCanvas() {
    this.setState({menuActive: false});
    document.querySelector('body').classList.remove('menu-open');
  }

  render() {

    let props = {
      onClick: this._hideOffCanvas,
      className: 'special',
      activeClassName: 'active',
    };

    return (
      <div>
        <header>
          <Link to="/" className="logo">
            <img src={brand} />
          </Link>
          <h2 className="title">
            { this.props.title }
          </h2>
          <button className="hamburger" onClick={this._showOffCanvas}>

            <span className="lines"></span>
          </button>
        </header>
        <div className={ this.state.menuActive ? 'off-canvas active' : 'off-canvas' }>
          <button className="hamburger active" onClick={this._hideOffCanvas}>
            <span className="lines"></span>
          </button>
          <nav className="off-canvas__nav">
            <ul>
              <li><Link to="/" exact={true} {...props}>Home<span>Home</span></Link></li>
              <li><Link to="/work" {...props}>Work<span>Work</span></Link></li>
              <li><Link to="/about" {...props}>About<span>About</span></Link></li>
              <li><Link to="/blog" {...props}>Blog<span>Blog</span></Link></li>
              <li><Link to="/contact" {...props}>Contact<span>Contact</span></Link></li>
            </ul>
          </nav>
          <ul className="off-canvas__social">
            <li><a href="https://www.facebook.com/round3films/" target="_blank"><i className="fa fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/round3films/" target="_blank"><i className="fa fa-instagram"></i></a></li>
            <li><a href="https://vimeo.com/round3" target="_blank"><i className="fa fa-vimeo"></i></a></li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Header
